<template>
  <section class="create-position">
    <div class="create-position__wrapper">
      <div class="create-position__intro">
        <p class="create-position__intro-greeting">
          {{ $t(`${langPath}.greeting`) }}
        </p>
        <h1 class="create-position__intro-title">
          {{ $t(`${langPath}.findTalent.letsFind`) }}
          <div class="create-position__intro-title--accent">
            {{ $t(`${langPath}.findTalent.rightTalent`) }}
          </div>
          {{ $t(`${langPath}.findTalent.toYourPosition`) }}
        </h1>
        <p class="create-position__intro-instructions">
          {{ $t(`${langPath}.fillInfo`) }}
        </p>
      </div>

      <div class="create-position__main">
        <form
          class="create-position__main-form"
          data-test="create-position-form"
          @submit.prevent="submitSearch"
        >
          <div class="create-position__main-form-container">
            <div class="create-position__main-form-container-item">
              <ev-input
                v-model="positionName"
                data-test="position-name-input"
                name="positionName"
                :errors="errorMessages($v.positionName)"
                :label="$t(`${langPath}.position.title`)"
                :placeholder="$t(`${langPath}.position.titlePlaceholder`)"
                @input="$v.positionName.$touch"
              />
            </div>
            <div class="create-position__main-form-container-item">
              <div class="create-position__main-form-container-item-flex">
                <div>
                  <p class="text">
                    {{ $t(`${langPath}.position.salaryRange`) }}
                  </p>
                  <ev-input
                    v-model="minimumSalary"
                    v-mask="currencyMask"
                    name="minimumSalary"
                    label=""
                    placeholder="Min."
                    data-test="minimum-salary-input"
                    :errors="minSalaryErrorMessage"
                    @input="$v.minimumSalaryInteger.$touch"
                  />
                </div>
                <div>
                  <p class="text__optional">
                    {{ $t(`${langPath}.position.optional`) }}
                  </p>
                  <ev-input
                    v-model="maximumSalary"
                    v-mask="currencyMask"
                    name="maximumSalary"
                    label=""
                    label-flag=""
                    placeholder="Max."
                    data-test="maximum-salary-input"
                    :errors="errorMessages($v.maximumSalaryInteger)"
                    @input="$v.maximumSalaryInteger.$touch"
                  />
                </div>
              </div>
            </div>
            <div class="create-position__main-form-container-item">
              <ev-select
                v-model="positionId"
                data-test="career-id-select"
                name="position"
                :label="$t(`${langPath}.position.career`)"
                :options="careerOptions"
                :placeholder="$t(`${langPath}.position.career`)"
                @input="$v.positionId.$touch"
              />
            </div>

            <div
              id="suggestedSkills"
              :class="skillsInputClasses"
            >
              <ev-circle-loader
                v-if="loading"
                title="Carregando"
                variant="tertiary"
              />
              <ev-tags-selector
                v-else
                v-model="selectedSkills"
                attr-label="label"
                attr-value="id"
                data-test="skills-input"
                theme="red"
                :label="$t(`${langPath}.position.skills`)"
                :loading="loading"
                :options="filteredSkills"
                :placeholder="$t(`${langPath}.position.skillsPlaceholder`)"
                @search="getSkillsFunction"
                @input="$v.selectedSkills.$touch"
              />
            </div>
          </div>
          <div class="create-position__main-form-actions">
            <ev-button
              data-test="next-step-button"
              :disabled="$v.$invalid"
              type="submit"
            >
              {{ $t(`${langPath}.searchProfessionals`) }}
            </ev-button>
            <ev-button
              class="create-position__main-form-actions-secondary"
              color="red"
              data-test="skip-onboarding-button"
              type="button"
              variant="tertiary"
              @click="skipOnboarding"
            >
              {{ $t(`${langPath}.skipStage`) }}
            </ev-button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import EvButton from '@revelotech/everestV2/EvButton'
import EvInput from '@revelotech/everestV2/EvInput'
import EvCircleLoader from '@revelotech/everestV2/EvCircleLoader'
import EvSelect from '@revelotech/everestV2/EvSelect'
import EvTagsSelector from '@revelotech/everestV2/EvTagsSelector'

import autocomplete from '@/repository/companies-app/autocomplete'
import careers from '@/repository/companies-app/careers'
import onboarding from '@/repository/companies-app/onboarding'

import { required, minLength, minValue, maxValue } from 'vuelidate/lib/validators'

import { createNamespacedHelpers } from 'vuex'
import { SIGNUP } from '@/store/namespaces'

import { companiesUrl } from '@/helpers/constants/external-urls'

const signupHelper = createNamespacedHelpers(SIGNUP)

const DEVELOPER_GROUP_ID = '8'

export default {
  name: 'NewEmployerOnboarding',

  components: {
    EvInput,
    EvButton,
    EvSelect,
    EvTagsSelector,
    EvCircleLoader
  },

  data () {
    return {
      langPath: __langpath,
      selectedSkills: [],
      suggestedSkills: [],
      filteredSkills: [],
      careerOptions: [],
      positionId: '',
      minimumSalary: '',
      maximumSalary: '',
      positionName: '',
      search: '',
      loading: true,
      marketplaceUrl: `${companiesUrl}/employers/candidates`
    }
  },

  validations () {
    return {
      minimumSalaryInteger: {
        maxValue: maxValue(this.maximumSalaryInteger)
      },
      maximumSalaryInteger: {
        minValue: minValue(this.minimumSalaryInteger)
      },
      positionName: {
        required
      },
      selectedSkills: {
        required,
        minLength: minLength(1)
      },
      positionId: {
        required
      }
    }
  },

  computed: {
    minSalaryErrorMessage () {
      return this.$v.minimumSalaryInteger.$invalid ? [''] : []
    },
    minimumSalaryInteger () {
      return parseInt(this.minimumSalary.replace(/[^\d]/g, '')) || 0
    },
    maximumSalaryInteger () {
      return parseInt(this.maximumSalary.replace(/[^\d]/g, '')) || 25000
    },
    currencyMask () {
      return [
        'R$ ###', 'R$ #.###', 'R$ ##.###', 'R$ ###.###', 'R$ #.###.###',
        'R$ ##.###.###', 'R$ ###.###.###'
      ]
    },
    skillsInputClasses () {
      return {
        'create-position__main-form-container-item-hidden': !this.positionId
      }
    }
  },

  watch: {
    async positionId (newValue, oldValue) {
      this.selectedSkills = []
      this.loading = true
      const skills = await careers.getPositionSkills(newValue)
      this.suggestedSkills = (skills).map((skill) => ({ id: skill.id, label: skill.name }))
      this.filteredSkills = this.suggestedSkills
      this.loading = false
    }
  },

  async created () {
    const filterCareers = await careers.getFiltersCareers()
    this.careerOptions = filterCareers.map(
      careerFilterGroup => ({
        label: careerFilterGroup.name,
        id: careerFilterGroup.id,
        options: careerFilterGroup.careers.map(
          career => ({
            label: career.name,
            value: career.id
          })
        )
      })
    ).sort(
      group => { return group.id === DEVELOPER_GROUP_ID ? -1 : 1 }
    )
  },

  methods: {
    ...signupHelper.mapActions(['getSearchUrl']),
    async getSkillsFunction (name) {
      if (name === '') {
        this.filteredSkills = this.suggestedSkills
      } else {
        const skills = await autocomplete.getSkills(name)
          .then((skills) => skills.map((skill) => ({ id: skill.id, label: skill.text }))
          )
        this.filteredSkills = skills
      }
    },
    skipOnboarding () {
      onboarding.completeOnboarding()
      window.location.assign(this.marketplaceUrl)
    },
    submitSearch () {
      const skillIds = this.selectedSkills.map((skill) => skill.id)
      const params = {
        title: this.positionName,
        careerId: this.positionId,
        skills: skillIds,
        minSalary: this.minimumSalaryInteger,
        maxSalary: this.maximumSalaryInteger
      }
      this.getSearchUrl(params)
      this.$gtmTrackEvent('position_created_new_webflow')
      this.$router.push({
        name: 'SearchSteps'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@revelotech/everestV2/tx_overline.scss";
// for some unknown reason related to the reset property,
// the following style is not applied when inside the
// create-position definition, only when outside.
.text {
  font-family: Open Sans, serif;
  font-size: 14px;
  font-weight: $base*100;
  letter-spacing: 0;
  line-height: $base*4;
  text-align: left;

  &__optional {
    font-family: Open Sans, serif;
    font-size: 14px;
    font-weight: $base*100;
    letter-spacing: 0;
    line-height: $base*4;
    text-align: right;
  }
}

.create-position {
  background: var(--bg-neutral-blank);
  overflow-y: auto;

  @include breakpoint(lg) {
    background: linear-gradient(90deg, var(--bg-red-light-03) 50%, var(--bg-neutral-blank) 0);
  }

  &__wrapper {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100vh;
    margin: auto;
    width: 100%;

    @include breakpoint(lg) {
      background: var(--bg-red-light-03);
      grid-gap: calc(6 * var(--gt-base));
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: auto;
      max-width: 1200px;
      min-height: 100vh;
    }
  }

  &__intro {
    border-bottom: var(--gt-base) solid var(--bg-red-light-03);
    display: flex;
    flex-direction: column;
    gap: calc(10 * var(--gt-base));
    padding:
      calc(10 * var(--gt-base))
      calc(6 * var(--gt-base))
      calc(4 * var(--gt-base))
      calc(6 * var(--gt-base));

    @include breakpoint(lg) {
      align-content: flex-start;
      border: none;
      grid-column: 1 / span 4;
      margin:
        calc(20 * var(--gt-base))
        calc(5 * var(--gt-base));
      padding: 0;
    }

    &-greeting {
      @extend %tx-overline;
    }

    &-title {
      color: var(--tx-neutral-dark-01);
      font: var(--tx-title-1);

      @include breakpoint(lg) {
        display: block;
      }

      &--accent {
        color: var(--tx-red);
      }
    }

    &-instructions {
      font: var(--font-size-body-1);
      max-width: 100%;
    }
  }

  &__main {
    background: var(--bg-neutral-blank);
    padding: calc(10 * var(--gt-base));

    @include breakpoint(lg) {
      grid-column: 5 / span 8;
      padding:
        $base*35
        calc(20 * var(--gt-base))
        calc(20 * var(--gt-base));
    }

    &-form {
      display: grid;
      grid-template-rows: 1fr auto;

      &-container {
        @include breakpoint(lg) {
          max-width: $base*100;
        }

        &-item {
          margin-bottom: $base*10;

          &-hidden {
            display: none;
          }

          &-flex {
            display: flex;
            justify-content: space-between;
          }
        }
      }

      &-actions {
        display: flex;
        justify-content: space-between;
        margin-top: $base*10;
        max-width: $base*100;

        &-secondary {
          color: $red;
        }
      }
    }
  }
}
</style>
