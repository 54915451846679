import { get } from '@/helpers/request'
import JSONAPIClient from '@/helpers/jsonapi-client'
import { companiesUrl } from '@/helpers/constants/external-urls'

const baseUrl = `${companiesUrl}/api/v2/autocomplete`

export default {
  async getBenefits (name) {
    const response = await get(`${baseUrl}/benefits?q=${name}`)

    return JSONAPIClient.parse(response.data)
  },

  async getSkills (name) {
    const response = await get(`${baseUrl}/skills?q=${name}`)

    return JSONAPIClient.parse(response.data)
  }
}
