import { post } from '@/helpers/request'
import { companiesUrl } from '@/helpers/constants/external-urls'

const baseUrl = `${companiesUrl}/api/v2/ats/employer_onboardings`

export default {
  async completeOnboarding () {
    return post(`${baseUrl}/complete_onboarding`)
  }
}
